body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.text-small{
  font-size: 12px;
  margin-top: -10px;
  padding-left: 20px;
}
.text-sma{
  font-size: 13px;
}
.bor{
  border: 1px solid red !important;
  border-radius: 40px !important; 
  margin-bottom: 10px; 
}
.filterbor{
  border: 1px solid #146de4  !important;
  border-radius: 40px !important; 
  margin-bottom: 10px; 
}
.poi{
  cursor: pointer;
}
.feedwidth{
  width:90%;
}
.deatailwidth{
  width:94%;
}
.mibottum{
  margin-bottom: 100px;
}
/*  */

.createpost .modal-dialog {
  background-color: transparent !important;
  box-shadow: none !important;
}

.createpost textarea {
  border-radius: 20px
}

.upload__inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload__btn {
  display: inline-block;
}

.upload__btn img {
  position: absolute;
  bottom: 20px
}

.upload__img-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.upload__img-box {
  display: none !important;
  position: relative;
}

.upload__img-box:nth-child(-n+5)  {
  display: inline-flex !important;
}
.upload__img-box div{
 position: relative;
}
.upload__img-box:nth-child(5) div:before{
  content: '';
  position: absolute;
  top:0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #000;
  opacity: 0.6;
  border-radius: 20px;
}
.img-count{
  z-index: 111;
  position: absolute;
  right: 55px;
  color: #fff;
  margin-top: 30px
}

.upload__img-box {
  padding: 0px 5px;
  margin-bottom: 5px;
}

.upload__img-close {
  display: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: center;
  line-height: 24px;
  z-index: 1;
  cursor: pointer;
}

.upload__img-close:after {
  content: "✖";
  font-size: 14px;
  color: white;
}

.img-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  width: 84px;
  height: 84px;
  border-radius: 20px;
}
.createpost .modal-body {
  padding: 20px !important
}
.createpost .modal-footer {
  padding: 20px !important
}
.createpost .modal-body h5 {
  font-weight: var(--fw-500);
  font-size: var(--font-18);
  line-height: var(--lh-24);
  margin-bottom: 0px
}

.createpost .modal-dialog {
  background-color: transparent !important;
  box-shadow: none !important;
}

.createpost textarea {
  border-radius: 20px
}

.bor-15{
  border-radius: 15px !important;
}

#carousel-indicators {
  margin-bottom: -15px;
}

.carouselItem {
  height: 275px;
}

.carouselItem img {
  height: 275px;
}

@media (max-width: 768px) {
  .carouselItem img, .carouselItem {
    border-radius: 1px;
  }
  #carousel-indicators {
    margin-bottom: 15px;
  }
  .carouselItem {
    height: 140px;
  }
  .carouselItem img {
    height: 155px;
  }
}
